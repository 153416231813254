<template>
	<main class="container">
		<div class="content">
			<div class="content_head">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>MY 서비스</li>
							<li>나의 서비스</li>
						</ul>
					</div>
					<h2 class="page_title">나의 서비스</h2>
				</div>
			</div>
			<div class="content_body">
				<div class="inner">
					<div class="service_apply">
						<a href="/service/info" class="btn_apply">서비스 신청(추가) 바로가기</a>
					</div>
					<div class="account_info">
						<template v-if="!isLogin()"><b>로그인이 필요한 서비스입니다</b></template>
						<template v-else><b>{{ getSessionProc().emailId }}</b> 님께서 신청한 서비스 이용 정보입니다.</template>
					</div>
					<div class="tbl_board">
						<table>
							<thead>
							<tr>
								<th>No.</th>
								<th>서비스 명</th>
								<th>서비스 이용 기간</th>
								<th>서비스 상태</th>
								<th>결제 유형</th>
								<th>신청일</th>
								<th>서비스 승인일</th>
							</tr>
							</thead>
							<tbody>

							<!-- 리스트 없을경우-->
							<template v-if="!isLogin() || myServiceList.length<0" >
								<tr>
									<td colspan="7" class="content_none">등록된 컨텐츠가 없습니다.</td>
								</tr>
							</template>
							<!-- 리스트 있을경우-->
							<template v-else>
								<tr v-for="(item, index) in myServiceList" @click="goForm(item.idx)" :key="'ms-' + index">
									<td data-td="No">{{ item.idx }}</td>
									<td data-td="서비스 명"><a href="#">{{ item.reqChannel | requestChannelFilter }}
										{{ item.isPremium == 'Y' ? ' 프리미엄' : '' }}
									</a></td>
									<td data-td="서비스 이용 기간">
										<!-- 승인 대기일 경우 이용날짜 없음.-->
										<template v-if="item.statusCode=='confirm'">
											{{ item.startDate | dateFilter }} ~ {{ item.endDate | dateFilter }}
										</template>
									</td>
									<td data-td="서비스 상태">{{ item.statusCode | statusCodeFilter }}</td>
									<td data-td="결제 유형">{{ item.paymentType | paymentFilter }}</td>
									<td data-td="신청일">{{ item.createDate | dateFilter }}</td>
									<td data-td="서비스 승인일">
										<!-- 승인 대기일 경우 승인일 없음.-->
										<template v-if="item.statusCode=='confirm'">
											{{ item.confirmDate | dateFilter}}
										</template>
									</td>
								</tr>
							</template>

							</tbody>
						</table>
					</div>

					<div class="pagination">
						<!-- TODO 이전페이지 다음페이지 이미지 어디갔담..? 수정 필요 -->
						<a href="#" class="prev"><span class="blind">이전 페이지</span></a>
						<a v-for="(item, index) in totalPage" :key="' tp'+index" href="#" @click="goPage(item-1)" :class="{ 'current': item == (page +1) }">{{item}}</a>
						<a href="#" class="next"><span class="blind">다음 페이지</span></a>
					</div>

				</div>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	name: "myList",
	data(){
		return {
			myServiceList  : [],
			page: 0,
			count: 0,
			size: 3,

			totalPage: 0,
			totalCount: 0
		}
	},
	mounted() {
		this.getMyServiceList();

		this.$eventBus.$emit("initApp")
	},
	methods:{
		goForm(_idx) {
			this.$router.push( { path: '/myService/myView', query: { idx: _idx } } )
		},

		goPage (_num) {
			this.page = _num;
			this.getMyServiceList();
		},

		getMyServiceList () {

			const self = this;

			if( this.isLogin() ) {

				this.$eventBus.$emit("startProgress")

				var temp = JSON.parse(JSON.stringify(this.$route.params))
				temp.size = this.size || 8
				temp.page = this.page || 0
				temp.emailId = this.getSessionProc().emailId;

				this.axiosCaller.get(this, this.APIs.MY_SERVICE + "/getMyServiceList", temp, (res) => {
					var result = res.data
					if (result.status === "ok") {
						self.myServiceList = result.list.content;
						self.totalCount = result.list.totalElements;
						self.totalPage = result.list.totalPages;

						self.$eventBus.$emit("initApp");
					} else {
						this.swalUtils.gritter("Error", result.msg, "error")
					}
					this.$eventBus.$emit("doneProgress")
				})

			}

		},
	}

}
</script>

<style scoped>

</style>